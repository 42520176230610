(function () {
  'use strict';

  angular
    .module('dashboard.admin')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.admin', {
        url: '/admin',
        templateUrl: 'dashboard/admin/admin.tpl.html',
        controller: 'AdminCtrl',
        roles: ['superadmin', 'admin', 'Teacher'],
        controllerAs: 'admin'
      });
  }
}());
